import React, { useRef } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import SourceInfo from './SourceInfo';
import { useMixpanel } from 'react-mixpanel-browser';

function InfoPanel({ el, source, ...props }) {
  const op = useRef(null);
  const mixpanel = useMixpanel();
  const hoverTimeout = useRef(null);
  const hoverOutTimeout = useRef(null);

  const handleMouseEnter = (event) => {
    if (op.current) {
      op.current.toggle(event);

      if (hoverTimeout?.current) {
        clearTimeout(hoverTimeout.current);
      }

      hoverTimeout.current = setTimeout(() => {
        handleEventTracking({ open: true });
      }, 1500);
    }
  };

  const handleMouseLeave = (event) => {
    if (op.current) {
      op.current.hide();

      if (hoverOutTimeout?.current) {
        clearTimeout(hoverOutTimeout.current);
      }

      hoverOutTimeout.current = setTimeout(() => {
        handleEventTracking({ open: false });
      }, 1500);
    }
  };

  function handleViewSource(url) {
    window.open(url, '_blank', 'noopener,noreferrer');
    if (props.closeonClick) {
      op?.current?.hide();
    }
  }

  function handleEventTracking({ open }) {
    const { study_summary, study_title, source: sourcename } = source;
    const eventName = open
      ? 'citation overlay opened event'
      : 'citation overlay closed event';

    mixpanel.track(eventName, {
      source: sourcename,
      study_summary,
      study_title,
    });
  }

  return (
    <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {el}
      <OverlayPanel
        onClick={(e) => e.stopPropagation()}
        hide
        ref={op}
        style={{
          backgroundColor: 'var(--bg-white)',
          border: '1px solid var(--border-neutral-200)',
          borderRadius: '12px',
          padding: '16px',
          maxWidth: '400px',
          maxHeight: '350px',
          overflow: 'auto',
        }}
        {...props}
      >
        <SourceInfo source={source} onViewSource={handleViewSource} {...props} />
      </OverlayPanel>
    </span>
  );
}

export default InfoPanel;
