import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  width: ${(props) => props.width || '50%'};
  height: ${(props) => props.height};
  border-radius: ${(props) => props.borderradius};
  margin-bottom: 5px;
`;

const StyledLine = styled.div`
  border: 0.5px solid var(--border-gray-light);
`;

export const StyledSourceCardDiv = styled.div`
  background: var(--bg-white);
  padding: 20px;
  border: 1px solid var(--border-neutral-200);
  border-radius: var(--border-radius-8);
  display: flex;
  flex-direction: column;
  transition: background-color 0.3s ease, transform 0.3s ease;
  gap: ${(props) => props.gap || '18px'};
  min-width: 20%;
  box-sizing: border-box;
  flex-wrap: wrap;
  flex: 1 0 100%;
  max-width: 100%;
  position: relative;
  @media (min-width: 768px) {
    flex: 1 0 calc(50% - 10px);
    max-width: ${(props) => props.width || 'calc(50% - 10px)'};
  }
  @media (min-width: 1024px) {
    flex: 1 0 calc(33.33% - 10px);
  }
  @media (min-width: 1200px) {
    flex: 1 0 calc(25% - 10px);
    max-width: ${(props) => props.width || 'calc(25% - 10px)'};
  }
`;

export function SourcesLoaderLayout() {
  return (
    <div>
      <div className="d-flex flex-wrap gap-10">
        {[...Array(4)].map((_, index) => (
          <React.Fragment key={index}>
            <SourcesLoader />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export function SourcesLoader() {
  return (
    <StyledSourceCardDiv>
      <StyledDiv className="skeleton-box light" height="5px"></StyledDiv>

      <div className="d-flex flex-column gap-4">
        <StyledDiv className="skeleton-box light" width="100%" height="5px"></StyledDiv>
        <StyledDiv className="skeleton-box light" width="100%" height="5px"></StyledDiv>
        <StyledDiv className="skeleton-box light" width="100%" height="5px"></StyledDiv>
      </div>

      <div className="d-flex align-items-center gap-10">
        <StyledDiv
          className="skeleton-box light"
          height="1.5rem"
          width="1.5rem"
          borderradius="50%"
        ></StyledDiv>
        <StyledDiv className="skeleton-box light" width="75%" height="5px"></StyledDiv>
      </div>
    </StyledSourceCardDiv>
  );
}

export function SummaryLoaderLayout({ showsidediv = true, title }) {
  return (
    <div className="mt-3 d-flex flex-column gap-20">
      <div className="d-flex align-items-center justify-content-between">
        {title ? (
          title
        ) : (
          <StyledDiv className="skeleton-box light" height="11px" width="20%"></StyledDiv>
        )}
        {showsidediv && (
          <StyledDiv
            className="skeleton-box light"
            height="35px"
            width="20%"
            borderradius="20px"
          ></StyledDiv>
        )}
      </div>

      <div className="d-flex flex-column gap-7">
        <StyledDiv className="skeleton-box light" width="90%" height="6px"></StyledDiv>
        <StyledDiv className="skeleton-box light" width="95%" height="6px"></StyledDiv>
        <StyledDiv className="skeleton-box light" width="85%" height="6px"></StyledDiv>
        <StyledDiv className="skeleton-box light" width="20%" height="6px"></StyledDiv>
        <StyledDiv className="skeleton-box light" width="90%" height="6px"></StyledDiv>
        <StyledDiv className="skeleton-box light" width="98%" height="6px"></StyledDiv>
        <StyledDiv className="skeleton-box light" width="10%" height="6px"></StyledDiv>
      </div>
    </div>
  );
}
