import React from 'react';
import styled from 'styled-components';
import { labels } from 'constants/labels';
import { Button } from 'components/button';
import atroposLogo from '../../assets/images/atropos_fav_logo.png';
import systemImage from 'assets/images/system.png';

const StyledImage = styled.img`
  height: 24px;
  width: 24px;
  border-radius: 50%;
`;

function SourceInfo({ source, isshowbutton = false, onViewSource }) {
  return (
    <div className="d-flex flex-column gap-10">
      <div className="d-flex align-items-center gap-10 w-100">
        <StyledImage
          src={source?.source === labels.Publications ? systemImage : atroposLogo}
          alt="close-icon"
        />

        <div className="txt size-14 lh-19 text-black-900">{source?.source_url}</div>
      </div>

      <div className="txt w-400 lh-22 size-14 text-black-900">
        {source?.title || source?.study_title}
      </div>

      <div className="txt lh-19 size-14 text-black-800">
        {source?.content || source?.study_summary}
      </div>

      {isshowbutton && (
        <Button
          outlined
          label="View Source"
          buttonClass="py-2"
          onClick={() => onViewSource(source?.study_url)}
        />
      )}
    </div>
  );
}

export default SourceInfo;
