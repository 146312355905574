import React, { useEffect, useState } from 'react';
import { labels } from 'constants/labels';
import { useSelector } from 'react-redux';

const TemplateBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { userData } = useSelector(({ userProfile }) => userProfile);

  useEffect(() => {
    const currentHost = window.location.host;
    // const enabledLocal = currentHost.includes('localhost');
    if (
      (currentHost.includes('portal.beta.atroposhealth.com')) &&
      !userData?.email?.endsWith('@atroposhealth.com')
    ) {
      setIsVisible(true);
    }
  }, [userData]);

  const closeBanner = () => {
    setIsVisible(false);
  };

  const logIn = () => {
    window.open('https://portal.atroposhealth.com/');
  };

  if (!isVisible) return null;

  return (
    <>
      <div id="banner" className="template-banner">
        <div className="banner-content">
          <div className="banner-title">
            <h4>ChatRWD has launched beyond beta! 🚀</h4>
            <p>
              Please login to the Atropos Health portal to continue using ChatRWD. Contact
              us if you have any questions or concerns.
            </p>
          </div>
          <div className="banner-buttons">
            <button className="banner-btn" onClick={logIn}>
              Log In
              <svg
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                className="forward-arrow"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
              </svg>
            </button>
            <a
              className="banner-btn banner-btn-secondary"
              href={`mailto:${labels.supportEmail}`}
            >
              Contact Support
            </a>
          </div>
        </div>
        <div className="banner-close" onClick={closeBanner}>
          x
        </div>
      </div>
    </>
  );
};

export default TemplateBanner;
