import React from 'react';
import { IconField } from 'components/form/iconField';
import { InputText } from 'components/form/input';
import { ReactComponent as Search } from 'assets/images/AiSearch.svg';
import { ReactComponent as AiMagicpen } from 'assets/images/AiMagicpen.svg';
import { labels } from 'constants/labels';
import { useMixpanel } from 'react-mixpanel-browser';
import orderScopeEnum from 'constants/enum/orderScopeEnum';
import { useSelector } from 'react-redux';

const iconsArr = [
  {
    component: <AiMagicpen />,
    key: labels.aiSearch,
    info: labels.answerWithAI,
  },
  {
    component: <Search />,
    key: labels.regularSearch,
    info: labels.basicSearch,
  },
];

function SearchField({ onIconClick, showclear, ...props }) {
  const { scope } = props;
  const mixpanel = useMixpanel();
  const { usersConfig } = useSelector(({ userProfile }) => userProfile);
  const { aiSearchState } = useSelector(({ searchOrder }) => searchOrder);
  const { aiSearchLoading, PubMedSearchLoading } = aiSearchState;
  const currentScope = scope ? scope : orderScopeEnum.PERSONAL;
  const iconsToRender = shouldAllowAiOrderSearch() ? iconsArr : [iconsArr[1]];
  const clearIconRightPosition = shouldAllowAiOrderSearch() ? '8rem' : '5rem';
  const inputfieldrightpadding = shouldAllowAiOrderSearch() ? '10.5rem' : '8.5rem';

  function shouldAllowAiOrderSearch() {
    return (
      currentScope === orderScopeEnum.LIBRARY &&
      usersConfig?.configuration?.prognostogram?.ai_order_search_access
    );
  }

  function handleIconClick(icon) {
    const action = icon?.info + ' icon' + ' clicked';
    mixpanel.track(action, {
      action: action,
      action_value: icon?.info,
    });
    onIconClick(icon);
  }

  return (
    <IconField
      inputfieldrightpadding={inputfieldrightpadding}
      cleariconrightposition={clearIconRightPosition}
      icons={iconsToRender}
      onIconClick={handleIconClick}
      showclear={showclear}
      style={{
        zIndex: 5,
      }}
      disabled={aiSearchLoading || PubMedSearchLoading ? true : false}
    >
      <InputText
        background="var(--bg-white)"
        padding="20px 20px 20px 12px"
        required
        {...props}
      />
    </IconField>
  );
}

export default SearchField;
