import React, { useEffect, useRef, useState } from 'react';
import Source from './Source';
import { labels } from 'constants/labels';
import CustomModal from 'components/CustomModal';
import { ReactComponent as Close } from 'assets/images/Close.svg';
import { useSelector } from 'react-redux';
import { SourcesLoaderLayout, SourcesLoader } from './AIOrderLoaderLayout';
import Message from '../../components/Message';

const SLICE_INDEX = 3;
const TILE_COUNT = 4;

function Sources() {
  const [activeIndex, setActiveIndex] = useState(undefined);
  const [open, setOpen] = useState(false);
  const modalRef = useRef(null);
  const { getPdfFileError } = useSelector(({ orderDetails }) => orderDetails);
  const { aiSearchState } = useSelector(({ searchOrder }) => searchOrder);
  const {
    sources,
    PubMedSearchLoading,
    aiSearchLoading,
    pubmedSearchError,
    aiSearchError,
  } = aiSearchState;

  const sliceIndex =
    aiSearchLoading || PubMedSearchLoading ? SLICE_INDEX - 1 : SLICE_INDEX;
  const onShowMoreTileCount =
    aiSearchLoading || PubMedSearchLoading ? TILE_COUNT - 1 : TILE_COUNT;
  const isAnySearchLoading = PubMedSearchLoading || aiSearchLoading;

  const extraAvatars = sources?.slice(3) || [];
  const counts = extraAvatars?.reduce(
    (acc, avatar) => {
      if (avatar.source === labels.Publications) acc.publications++;
      if (avatar.source === labels.atroposHealth) acc.atropos++;
      return acc;
    },
    { publications: 0, atropos: 0 },
  );

  let avatarsArray = [];
  if (counts.atropos > 0 && counts.publications > 0) {
    let takeAtropos = Math.ceil(6 / 2);
    let takePublications = 6 - takeAtropos;

    if (counts.atropos < takeAtropos) {
      takePublications += takeAtropos - counts.atropos;
      takeAtropos = counts.atropos;
    } else if (counts.publications < takePublications) {
      takeAtropos += takePublications - counts.publications;
      takePublications = counts.publications;
    }

    avatarsArray = [
      ...Array(takeAtropos).fill('atropos'),
      ...Array(takePublications).fill('publications'),
    ];
  } else if (counts.atropos > 0) {
    avatarsArray = Array(Math.min(6, counts.atropos)).fill('atropos');
  } else if (counts.publications > 0) {
    avatarsArray = Array(Math.min(6, counts.publications)).fill('publications');
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        !event.target.closest('[data-ignore-outside-click]')
      ) {
        handleModalClose();
      }
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, handleModalClose]);

  function handleModalClose() {
    setOpen(false);
  }

  function handleClick(uri) {
    setActiveIndex(uri);
  }

  function handleViewMoreSources() {
    setActiveIndex(undefined);
    setOpen((open) => !open);
  }

  if (PubMedSearchLoading && aiSearchLoading) {
    return (
      <>
        <div className="txt size-16 w-700 lh-22 text-primary-1">{labels.SOURCES}</div>
        <SourcesLoaderLayout />
      </>
    );
  }

  return (
    <>
      <div className="d-flex flex-column gap-10">
        <div className="txt size-16 w-700 lh-22 text-primary-1">{labels.SOURCES}</div>
        {sources && sources?.length > 0 ? (
          <>
            <div className="d-flex flex-wrap gap-10">
              {sources.length <= onShowMoreTileCount ? (
                <>
                  {(PubMedSearchLoading || aiSearchLoading) && <SourcesLoader />}
                  {sources.map((source, index) => (
                    <React.Fragment
                      key={`${source?.document_uri || source?.study_id}-${index}`}
                    >
                      <Source
                        source={source}
                        onClick={handleClick}
                        activeIndex={activeIndex}
                        index={index}
                        isModal={false}
                      />
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <>
                  {(PubMedSearchLoading || aiSearchLoading) && <SourcesLoader />}
                  {sources.slice(0, sliceIndex).map((source, index) => (
                    <React.Fragment key={`${source?.document_uri}-${index}`}>
                      <Source
                        source={source}
                        onClick={handleClick}
                        activeIndex={activeIndex}
                        index={index}
                        isModal={false}
                      />
                    </React.Fragment>
                  ))}

                  <Source
                    source={{
                      name: labels?.otherSources,
                      title: `View ${sources.length - 3} more sources`,
                      avatars: avatarsArray,
                    }}
                    onClick={handleViewMoreSources}
                    showInfoOverlay={false}
                    isModal={false}
                  />
                </>
              )}
            </div>
            <CustomModal
              isOpen={open}
              cssClass="white-overlay"
              modalname="View All Sources Modal"
              overlayCssClass="phi-modal-overlay"
              handleClose={() => {}}
            >
              <div
                ref={modalRef}
                className="ah-modal-container d-flex flex-column gap-16"
                style={{ backgroundColor: 'var(--bg-gray-50)' }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="txt size-16 w-600">{`${
                    sources.length - 3
                  } sources`}</div>
                  <Close className="cursor-pointer" onClick={handleViewMoreSources} />
                </div>
                <div className="d-flex flex-column flex-wrap">
                  {sources.slice(3).map((source, index) => (
                    <React.Fragment key={`${source?.document_uri}-${index}`}>
                      <Source
                        source={source}
                        onClick={handleClick}
                        activeIndex={activeIndex}
                        index={index}
                        cardwidth="100%"
                        truncatedtextwidth="75"
                        isModal={true}
                      />

                      <div className="my-2">
                        {open &&
                          getPdfFileError &&
                          activeIndex &&
                          activeIndex === source?.document_uri && (
                            <div className="txt text-danger size-12">
                              {getPdfFileError}
                            </div>
                          )}
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </CustomModal>
          </>
        ) : (
          isAnySearchLoading && <SourcesLoaderLayout />
        )}

        {!PubMedSearchLoading && !aiSearchLoading && (
          <>
            {sources?.length === 0 && (
              <Message severity="info" text={labels.noSourcesAvailable} />
            )}
            {!open && getPdfFileError && activeIndex && (
              <div className="color-error error-text txt w-400">{getPdfFileError}</div>
            )}
            {pubmedSearchError && (
              <div className="color-error error-text txt w-400">{pubmedSearchError}</div>
            )}
            {aiSearchError && (
              <div className="color-error error-text txt w-400">{aiSearchError}</div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Sources;
