import {
  GET_ORDER_DETAILS_START,
  GET_ORDER_DETAILS_COMPLETE,
  GET_ORDER_DETAILS_ERROR,
  GET_SEARCH_QUERY_DETAILS_START,
  GET_SEARCH_QUERY_DETAILS_COMPLETE,
  GET_SEARCH_QUERY_DETAILS_ERROR,
  GET_SEARCH_DATA_START,
  GET_SEARCH_DATA_COMPLETE,
  GET_SEARCH_DATA_ERROR,
  GET_ORDER_STATUS_COUNT_START,
  GET_ORDER_STATUS_COUNT_COMPLETE,
  GET_ORDER_STATUS_COUNT_ERROR,
  SET_ERROR,
  SET_LOADING,
  RESET_DATA,
  GET_QUESTION_TYPES_START,
  GET_QUESTION_TYPES_COMPLETE,
  GET_QUESTION_TYPES_ERROR,
  ORDER_PROGNOSTOGRAM_START,
  ORDER_PROGNOSTOGRAM_COMPLETE,
  ORDER_PROGNOSTOGRAM_ERROR,
  MODIFY_PROGNOSTOGRAM_ORDER_START,
  MODIFY_PROGNOSTOGRAM_ORDER_COMPLETE,
  MODIFY_PROGNOSTOGRAM_ORDER_ERROR,
  SET_ORDER_FORM_DATA,
  SET_ORDER_SCOPE_DATA,
  SET_SEARCH_QUERY_TEXT,
  SET_SPECIALTY_TEXT,
  CLEAR_ORDER_FORM_DATA,
  GET_PROGNOSTOGRAM_PDF_START,
  GET_PROGNOSTOGRAM_PDF_COMPLETE,
  GET_PROGNOSTOGRAM_PDF_ERROR,
  DELETE_ORDER_START,
  DELETE_ORDER_COMPLETE,
  DELETE_ORDER_ERROR,
  GET_SPECIALTY_LIST_START,
  GET_SPECIALTY_LIST_COMPLETE,
  GET_SPECIALTY_LIST_ERROR,
  RESET_ORDER_INDEX_DATA,
  SET_ORDER_INDEX_DATA,
  GET_ORDER_BY_GUID_START,
  GET_ORDER_BY_GUID_COMPLETE,
  GET_ORDER_BY_GUID_ERROR,
  RESET_SINGLE_ORDER_DATA,
  UPLOAD_ORDER_DOCUMENT_START,
  UPLOAD_ORDER_DOCUMENT_COMPLETE,
  UPLOAD_ORDER_DOCUMENT_ERROR,
  RESET_UPLOADED_DOCUMENT_DATA,
  GET_ORDER_DOCUMENT_START,
  GET_ORDER_DOCUMENT_COMPLETE,
  GET_ORDER_DOCUMENT_ERROR,
  RESET_ORDER_DOCUMENT,
  GET_MOTIVATIONS_START,
  GET_MOTIVATIONS_COMPLETE,
  GET_MOTIVATIONS_ERROR,
  GET_DOWNLOAD_LINK_START,
  GET_DOWNLOAD_LINK_COMPLETE,
  GET_DOWNLOAD_LINK_ERROR,
  RESET_DOWNLOAD_LINK,
  RESET_PROGNOSTOGRAM_PDF,
  FEATCH_RESULT_THROUGH_OPEN_AI_START,
  FEATCH_RESULT_THROUGH_OPEN_AI_COMPLETE,
  FEATCH_RESULT_THROUGH_OPEN_AI_ERROR,
  FEATCH_PICO_TO_PHENOTYPE_START,
  FEATCH_PICO_TO_PHENOTYPE_COMPLETE,
  FEATCH_PICO_TO_PHENOTYPE_ERROR,
  RESET_PHENOTYPE_DATA,
  SAVE_PICO_OPTIONS_START,
  SAVE_PICO_OPTIONS_COMPLETE,
  SAVE_PICO_OPTIONS_ERROR,
  RESET_PICO_OPTIONS_DATA,
  RESET_PICO_DATA,
  RESET_CHATRWD_ERRORS,
  SET_CHATRWD_LOADER_CLOSE_CLICKED,
  FEATCH_ORDER_TASK_STATUS_START,
  FEATCH_ORDER_TASK_STATUS_COMPLETE,
  FEATCH_ORDER_TASK_STATUS_ERROR,
  SET_SEARCH_ORDER_LOADING,
  SET_UPLOADED_FILE,
  REMOVE_UPLOADED_FILE,
  CLEAR_UPLOADED_DOCUMENTS_LIST,
  FEATCH_SUGGESTIONS_THROUGH_OPEN_AI_START,
  FEATCH_SUGGESTIONS_THROUGH_OPEN_AI_COMPLETE,
  FEATCH_SUGGESTIONS_THROUGH_OPEN_AI_ERROR,
  RESET_SUGGESTIONS,
  SET_PICO_GUID,
  INCORPORATE_SUGGESTIONS_THROUGH_OPEN_AI_START,
  INCORPORATE_SUGGESTIONS_THROUGH_OPEN_AI_COMPLETE,
  INCORPORATE_SUGGESTIONS_THROUGH_OPEN_AI_ERROR,
  SET_SUGGESTIONS_WITH_INDEX,
  REMOVE_SUGGESTIONS_WITH_INDEX,
  HANDLE_ORDERDETAIL_MODAL,
  GET_ORDER_NOTIFICATION_DETAILS_COMPLETE,
  GET_ORDER_NOTIFICATION_DETAILS_START,
  GET_ORDER_NOTIFICATION_DETAILS_ERROR,
  RESET_ORDER_NOTIFICATION_DETAILS,
  HANDLE_CANCEL_ORDER_MODAL,
  SET_MOTIVATION,
  SET_CURRENT_ORDER_STATUS,
  UPDATE_PICO_SUGGESTIONS,
  RESET_STUDY_FORMATS,
  CLEAR_STUDY_FORMATS,
  UPDATE_STUDY_DETAIL_KEY_WITH_INDEX,
  REMOVE_STUDY_FORMAT_WITH_INDEX,
  UPDATE_STUDY_DETAIL_DATA,
  SET_PHENOTYPE_DATA,
  SYNC_MODIFIED_PHENOTYPE_DATA_COMPLETE,
  SYNC_MODIFIED_PHENOTYPE_DATA_ERROR,
  SYNC_MODIFIED_PHENOTYPE_DATA_START,
  GET_STUDY_DETAILS_COMPLETE,
  GET_STUDY_DETAILS_ERROR,
} from './actions';
import * as actions from './actions';
import { ORDER_PAGINATION_SIZE } from 'constants/Constants';

let initialState = {
  isOrderLoading: false,
  questionData: [],
  orderData: {},
  getQuestionError: undefined,
  orderPrognostogramError: undefined,
  getOrderError: undefined,
  getSearchQueryDeatilsError: undefined,
  searchListData: {},
  pdfFile: '',
  pageNumber: 1,
  hasNoOrders: false,
  hasMoreOrders: false,
  hasMoreSearchOrders: false,
  searchPageNumber: 1,
  orderListCount: 0,
  orderIndexData: {},
  isUploadingDocument: false,
  getDocumentUploadError: undefined,
  fileDowloadLink: undefined,
  openAiResponse: undefined,
  isOpenAiLoading: false,
  openAiError: undefined,
  phenotypeResponse: undefined,
  openAiPhenotypeError: undefined,
  isShowOpenAiPhenotypeLoading: false,
  savedPicoGuid: undefined,
  picoSavingError: undefined,
  isShowSavePicoLoading: false,
  picotData: undefined,
  isChatrwdLoaderCloseClicked: false,
  orderTaskStatusError: undefined,
  isSearchOrderLoading: false,
  uploadedFilesList: [],
  studyFormats: [],
  isLoadingStudyFormats: false,
  PICOGuid: undefined,
  isNotificationModalOpen: false,
  orderNotificationDetailsLoading: false,
  orderNotificationDetails: undefined,
  orderNotificationDetailsError: undefined,
  orderGuidToCancel: null,
  currentOrderStatus: undefined,
  currentOrderMotivation: undefined,
  studyFormat: {},
  aIResponseErrors: undefined,
  syncedPhenotype: undefined,
  rootPhenotypeResponse: undefined,
};

const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_SPECIALTY_LIST_START:
      return {
        ...state,
        getSpecialtyListError: undefined,
      };
    case GET_SPECIALTY_LIST_COMPLETE:
      const specialtyList = [...payload.specialtyData];

      return {
        ...state,
        specialtyData: specialtyList,
        getSpecialtyListError: undefined,
      };
    case GET_SPECIALTY_LIST_ERROR:
      return { ...state, getSpecialtyListError: payload.error, ...payload };
    case RESET_SINGLE_ORDER_DATA:
      return {
        ...state,
        singleOrderData: payload,
        ...payload,
      };
    case GET_ORDER_BY_GUID_START:
      return {
        ...state,
        getOrderDetailError: undefined,
        isgettingOrderDetails: true,
        studyFormats: [],
        singleOrderData: {},
      };
    case GET_ORDER_BY_GUID_COMPLETE: {
      const { ai_search_history } = payload?.data || {};
      const chatrwdData = ai_search_history;
      let dataObj = {};
      let phenotypeResponse = {};
      let rootPhenotypeResponse = {};

      if (chatrwdData?.response_pico) {
        dataObj = {
          ...chatrwdData.response_pico,
          display_text_1: chatrwdData.display_text_1,
          display_text_2: chatrwdData.display_text_2,
        };
        phenotypeResponse = {
          phenotypes: chatrwdData?.user_phenotypes,
          buttons: chatrwdData?.phenotype_buttons,
          inline_buttons: chatrwdData?.inline_buttons,
        };
        rootPhenotypeResponse = {
          phenotypes: chatrwdData?.user_phenotypes,
          buttons: chatrwdData?.phenotype_buttons,
        };
      }

      const studyFormats = Object.keys(dataObj).length > 0 ? [dataObj] : [];

      return {
        ...state,
        singleOrderData: payload?.data,
        getOrderDetailError: undefined,
        uploadedFilesList: payload?.data?.documents.length
          ? payload?.data?.documents
          : [],
        studyFormats,
        studyFormat:
          chatrwdData?.response_pico?.study_format &&
          Object.keys(chatrwdData?.response_pico?.study_format).length
            ? chatrwdData?.response_pico?.study_format
            : state.studyFormat,
        PICOGuid: chatrwdData?.guid,
        picoSummaryQuestion: payload?.data?.clinical_questions,
        phenotypeResponse: phenotypeResponse,
        rootPhenotypeResponse: rootPhenotypeResponse?.phenotypes,
        isgettingOrderDetails: false,
        picoSuggestions: [dataObj],
        study_design: chatrwdData?.response_pico?.study_design,
      };
    }
    case GET_ORDER_BY_GUID_ERROR:
      return {
        ...state,
        getOrderDetailError: payload.error,
        singleOrderData: {},
        isgettingOrderDetails: false,
        studyFormats: [],
        ...payload,
      };
    case UPLOAD_ORDER_DOCUMENT_START:
      return {
        ...state,
        getDocumentUploadError: undefined,
        isUploadingDocument: true,
      };
    case UPLOAD_ORDER_DOCUMENT_COMPLETE:
      return {
        ...state,
        uploadedDocumentInfo: payload.data,
        getDocumentUploadError: undefined,
        isUploadingDocument: false,
      };
    case UPLOAD_ORDER_DOCUMENT_ERROR:
      return {
        ...state,
        getDocumentUploadError: payload.error,
        uploadedDocumentInfo: {},
        isUploadingDocument: false,
        ...payload,
      };
    case RESET_UPLOADED_DOCUMENT_DATA:
      return {
        ...state,
        getDocumentUploadError: undefined,
        uploadedDocumentInfo: {},
        isUploadingDocument: false,
        ...payload,
      };
    case GET_DOWNLOAD_LINK_START:
      return {
        ...state,
        resource: undefined,
        getFileDownloadError: undefined,
        fileDowloadLink: undefined,
        isFetchingDownloadLinkStarted: true,
      };
    case GET_DOWNLOAD_LINK_COMPLETE:
      return {
        ...state,
        resource: payload.resource,
        getFileDownloadError: undefined,
        fileDowloadLink: payload.data,
        isFetchingDownloadLinkStarted: false,
      };
    case GET_DOWNLOAD_LINK_ERROR:
      return {
        ...state,
        resource: payload.resource,
        getFileDownloadError: payload.error,
        fileDowloadLink: undefined,
        isFetchingDownloadLinkStarted: false,
        ...payload,
      };
    case RESET_DOWNLOAD_LINK:
      return {
        ...state,
        resource: undefined,
        fileDowloadLink: undefined,
        isFetchingDownloadLinkStarted: false,
        getFileDownloadError: undefined,
        ...payload,
      };
    case GET_QUESTION_TYPES_START:
      return {
        ...state,
        getQuestionError: undefined,
      };
    case GET_QUESTION_TYPES_COMPLETE:
      const questionList = [...payload.questionData];

      return {
        ...state,
        questionData: questionList,
        getQuestionError: undefined,
      };
    case GET_QUESTION_TYPES_ERROR:
      return { ...state, getQuestionError: payload.error, ...payload };

    case GET_MOTIVATIONS_START:
      return {
        ...state,
        getMotivationsError: undefined,
      };
    case GET_MOTIVATIONS_COMPLETE:
      let motivations = payload.motivationsData.map((motivationText) => ({
        category: motivationText,
        value: false,
      }));
      const research = motivations.find(
        (motivation) => motivation.category == 'Research',
      );
      const patientCare = motivations.find(
        (motivation) => motivation.category == 'Patient Care',
      );
      const careProtocol = motivations.find(
        (motivation) => motivation.category == 'Care Protocol',
      );
      const unorderedMotivations = motivations.filter(
        (motivation) =>
          motivation.category != 'Research' &&
          motivation.category != 'Patient Care' &&
          motivation.category != 'Care Protocol',
      );
      motivations = [research, patientCare, careProtocol, ...unorderedMotivations];
      motivations = motivations.filter((motivation) => motivation); //filter in case any of the above motivations not found

      return {
        ...state,
        motivations: motivations,
        getMotivationsError: undefined,
      };
    case GET_MOTIVATIONS_ERROR:
      return { ...state, getMotivationsError: payload.error, ...payload };

    case ORDER_PROGNOSTOGRAM_START:
      return {
        ...state,
        orderPrognostogramError: undefined,
        isChatrwdLoaderCloseClicked: false,
      };
    case ORDER_PROGNOSTOGRAM_COMPLETE:
      return {
        ...state,
        orderPrognostogramError: undefined,
      };
    case ORDER_PROGNOSTOGRAM_ERROR:
      return { ...state, orderPrognostogramError: payload.error, ...payload };
    case MODIFY_PROGNOSTOGRAM_ORDER_START:
      return {
        ...state,
        orderPrognostogramError: undefined,
      };
    case MODIFY_PROGNOSTOGRAM_ORDER_COMPLETE:
      return {
        ...state,
        orderPrognostogramError: undefined,
      };
    case MODIFY_PROGNOSTOGRAM_ORDER_ERROR:
      return { ...state, orderPrognostogramError: payload.error, ...payload };
    case GET_ORDER_DETAILS_START:
      return {
        ...state,
        getOrderError: undefined,
        waitingForOrders: true,
        orderListCount: 0,
      };
    case GET_ORDER_DETAILS_COMPLETE:
      let canPaginate = false;
      let noOrders = false;
      const orderList =
        payload.pageNumber === 1 || payload.isIndexSet
          ? [...payload.orderData]
          : [...state.orderData, ...payload.orderData];

      if (payload.pageNumber === 1 && orderList.length === 0) {
        noOrders = true;
      }

      if (payload.total_pages - payload.pageNumber > 0) {
        canPaginate = true;
      } else {
        canPaginate = false;
      }
      return {
        ...state,
        hasMoreOrders: canPaginate,
        orderData: orderList,
        pageNumber: payload.pageNumber + 1,
        hasNoOrders: noOrders,
        getOrderError: undefined,
        waitingForOrders: false,
        orderListCount: payload.orderListCount,
      };
    case GET_ORDER_DETAILS_ERROR:
      return {
        ...state,
        getOrderError: payload.error,
        waitingForOrders: false,
        ...payload,
      };
    case GET_SEARCH_QUERY_DETAILS_START:
      return {
        ...state,
        getSearchQueryDeatilsError: undefined,
      };
    case GET_SEARCH_QUERY_DETAILS_COMPLETE:
      return {
        ...state,
        searchQueryListData: payload.searchData,
        getSearchQueryDeatilsError: undefined,
      };
    case GET_SEARCH_QUERY_DETAILS_ERROR:
      return { ...state, getSearchQueryDeatilsError: payload.error, ...payload };
    case GET_SEARCH_DATA_START:
      return {
        ...state,
        getOrderBySearchError: undefined,
        getPdfFileError: undefined,
      };
    case GET_SEARCH_DATA_COMPLETE:
      let canSearchPaginate = false;
      let noSearchOrders = false;
      const searchOrderList =
        payload.searchPageNumber === 1 || payload.isIndexSet
          ? [...payload.searchData]
          : [...state.searchListData, ...payload.searchData];

      if (payload.searchPageNumber === 1 && searchOrderList.length === 0) {
        noSearchOrders = true;
      }
      if (payload.total_pages - payload.searchPageNumber > 0) {
        canSearchPaginate = true;
      } else {
        canSearchPaginate = false;
      }
      return {
        ...state,
        hasMoreSearchOrders: canSearchPaginate,
        searchPageNumber: payload.searchPageNumber + 1,
        hasNoOrders: noSearchOrders,
        searchListData: searchOrderList,
        getOrderBySearchError: undefined,
        count: payload.count,
        ai_results: payload?.ai_results,
      };
    case GET_SEARCH_DATA_ERROR:
      return { ...state, getOrderBySearchError: payload.error, ...payload };

    case GET_ORDER_STATUS_COUNT_START:
      return {
        ...state,
        getOrderStatusError: undefined,
      };
    case GET_ORDER_STATUS_COUNT_COMPLETE:
      return {
        ...state,
        orderStatusCountData: payload.orderStatusData,
        getOrderStatusError: undefined,
      };
    case GET_ORDER_STATUS_COUNT_ERROR:
      return { ...state, getOrderStatusError: payload.error, ...payload };
    case GET_PROGNOSTOGRAM_PDF_START:
      return {
        ...state,
        pdfFile: undefined,
        isPdfLoading: true,
        getPdfFileError: undefined,
      };
    case GET_PROGNOSTOGRAM_PDF_COMPLETE:
      return {
        ...state,
        pdfFile: payload.pdfFile,
        getPdfFileError: undefined,
        isPdfLoading: false,
      };
    case GET_PROGNOSTOGRAM_PDF_ERROR:
      return {
        ...state,
        getPdfFileError: payload.error,
        ...payload,
        isPdfLoading: false,
      };
    case RESET_PROGNOSTOGRAM_PDF:
      return {
        ...state,
        pdfFile: undefined,
        getPdfFileError: undefined,
        isPdfLoading: false,
        ...payload,
      };
    case DELETE_ORDER_START:
      return {
        ...state,
        deleteOrderError: undefined,
      };
    case DELETE_ORDER_COMPLETE:
      return {
        ...state,
        deleteOrderError: undefined,
      };
    case DELETE_ORDER_ERROR:
      return { ...state, deleteOrderError: payload.error, ...payload };
    case FEATCH_RESULT_THROUGH_OPEN_AI_START:
      return {
        ...state,
        isOpenAiLoading: true,
        aIResponseErrors: undefined,
        studyFormats: [],
      };
    case FEATCH_RESULT_THROUGH_OPEN_AI_COMPLETE:
      return {
        ...state,
        isOpenAiLoading: false,
        picoSummaryQuestion: payload.picoSummaryQuestion,
        studyFormats: [...state.studyFormats, payload.data],
        studyFormat:
          payload?.data?.study_format && Object.keys(payload.data.study_format).length
            ? payload.data.study_format
            : state.studyFormat,
        study_design: payload?.data?.study_design,
      };
    case FEATCH_RESULT_THROUGH_OPEN_AI_ERROR:
      return {
        ...state,
        aIResponseErrors: { ...state.aIResponseErrors, openAiError: payload.error },
        isOpenAiLoading: false,
        ...payload,
      };
    case FEATCH_PICO_TO_PHENOTYPE_START:
      return {
        ...state,
        phenotypeResponse: undefined,
        rootPhenotypeResponse: undefined,
        isPhenotypeLoading: true,
        aIResponseErrors: undefined,
        phenotypeValidationsErrors: undefined,
      };
    case FEATCH_PICO_TO_PHENOTYPE_COMPLETE:
      return {
        ...state,
        phenotypeResponse: payload.data,
        rootPhenotypeResponse: payload?.data?.phenotypes,
        isPhenotypeLoading: false,
      };
    case FEATCH_PICO_TO_PHENOTYPE_ERROR:
      return {
        ...state,
        phenotypeResponse: undefined,
        rootPhenotypeResponse: undefined,
        aIResponseErrors: {
          ...state.aIResponseErrors,
          openAiPhenotypeError: payload.error,
        },
        isPhenotypeLoading: false,
        ...payload,
      };
    case RESET_PHENOTYPE_DATA:
      return {
        ...state,
        phenotypeResponse: undefined,
        rootPhenotypeResponse: undefined,
        openAiPhenotypeError: undefined,
        isOpenAiLoading: false,
        ...payload,
      };
    case SAVE_PICO_OPTIONS_START:
      return {
        ...state,
        savedPicoGuid: undefined,
        isOpenAiLoading: true,
        aIResponseErrors: undefined,
      };
    case SAVE_PICO_OPTIONS_COMPLETE:
      return {
        ...state,
        aIResponseErrors: undefined,
        studyFormats: [...state.studyFormats, payload.data],
        picoSummaryQuestion: payload?.data?.query,
        studyFormat:
          payload?.data?.study_format && Object.keys(payload.data.study_format).length
            ? payload.data.study_format
            : state.studyFormat,
        study_design: payload?.data?.study_design,
        isOpenAiLoading: false,
      };
    case SAVE_PICO_OPTIONS_ERROR:
      return {
        ...state,
        savedPicoGuid: undefined,
        aIResponseErrors: { ...state.aIResponseErrors, picoSavingError: payload.error },
        isOpenAiLoading: false,
        ...payload,
      };
    case FEATCH_ORDER_TASK_STATUS_START:
      return {
        ...state,
        orderTaskStatusError: undefined,
      };
    case FEATCH_ORDER_TASK_STATUS_COMPLETE:
      return {
        ...state,
        orderTaskStatusError: undefined,
      };
    case FEATCH_ORDER_TASK_STATUS_ERROR:
      return {
        ...state,
        orderTaskStatusError: payload.error,
        ...payload,
      };
    case RESET_PICO_OPTIONS_DATA:
      return {
        ...state,
        savedPicoGuid: undefined,
        picoSavingError: undefined,
        isShowSavePicoLoading: false,
        ...payload,
      };
    case RESET_PICO_DATA:
      return {
        ...state,
        picotData: undefined,
        openAiResponse: undefined,
        PICOGuid: undefined,
        picoSummaryQuestion: undefined,
        studyFormat: {},
        ...payload,
      };
    case RESET_CHATRWD_ERRORS:
      return {
        ...state,
        openAiError: undefined,
        picoSavingError: undefined,
        openAiPhenotypeError: undefined,
        orderPrognostogramError: undefined,
        orderTaskStatusError: undefined,
        ...payload,
      };
    case SET_ERROR:
      return { ...state, isOrderError: payload.data };
    case SET_LOADING:
      return { ...state, isOrderLoading: payload.data };
    case RESET_DATA:
      return {
        ...state,
        getQuestionError: undefined,
        orderPrognostogramError: undefined,
      };
    case SET_ORDER_FORM_DATA:
      return {
        ...state,
        orderFormData: payload,
        ...payload,
      };
    case SET_ORDER_INDEX_DATA:
      return {
        ...state,
        orderIndexData: payload,
        ...payload,
      };
    case RESET_ORDER_INDEX_DATA:
      return {
        ...state,
        orderIndexData: payload,
        ...payload,
      };
    case SET_ORDER_SCOPE_DATA:
      return {
        ...state,
        orderScopeData: payload,
        ...payload,
      };
    case SET_SEARCH_QUERY_TEXT:
      return {
        ...state,
        searchData: payload,
        ...payload,
      };
    case SET_SPECIALTY_TEXT:
      return {
        ...state,
        specialtyTextData: payload,
        ...payload,
      };
    case CLEAR_ORDER_FORM_DATA:
      return {
        ...state,
        orderFormData: {},
        ...payload,
      };
    case SET_CHATRWD_LOADER_CLOSE_CLICKED:
      return {
        ...state,
        isChatrwdLoaderCloseClicked: payload.clicked,
        ...payload,
      };
    case SET_SEARCH_ORDER_LOADING:
      return { ...state, isSearchOrderLoading: payload };
    case SET_UPLOADED_FILE:
      if (Object.prototype.toString.call(payload) === '[object Array]') {
        return {
          ...state,
          uploadedFilesList: [...state.uploadedFilesList, ...payload],
        };
      } else {
        return {
          ...state,
          uploadedFilesList: [...state.uploadedFilesList, payload],
        };
      }
    case REMOVE_UPLOADED_FILE:
      var files = [...state.uploadedFilesList];
      files.splice(payload, 1);
      return {
        ...state,
        uploadedFilesList: files,
      };
    case CLEAR_UPLOADED_DOCUMENTS_LIST:
      return {
        ...state,
        uploadedFilesList: [],
      };
    case FEATCH_SUGGESTIONS_THROUGH_OPEN_AI_START:
      return {
        ...state,
        isOpenAiLoading: true,
        aIResponseErrors: undefined,
      };
    case FEATCH_SUGGESTIONS_THROUGH_OPEN_AI_COMPLETE:
      return {
        ...state,
        aIResponseErrors: undefined,
        studyFormats: [...state.studyFormats, payload.data],
        picoSummaryQuestion: payload?.data?.query,
        isOpenAiLoading: false,
      };
    case FEATCH_SUGGESTIONS_THROUGH_OPEN_AI_ERROR:
      return {
        ...state,
        aIResponseErrors: {
          ...state.aIResponseErrors,
          openAiSuggestionError: payload.error,
        },
        isOpenAiLoading: false,
        ...payload,
      };
    case INCORPORATE_SUGGESTIONS_THROUGH_OPEN_AI_START:
      return {
        ...state,
        isOpenAiLoading: true,
        aIResponseErrors: undefined,
      };
    case INCORPORATE_SUGGESTIONS_THROUGH_OPEN_AI_COMPLETE:
      return {
        ...state,
        studyFormats: [...state.studyFormats, payload.data],
        picoSummaryQuestion: payload.picoSummaryQuestion,
        studyFormat:
          payload?.data?.study_format && Object.keys(payload.data.study_format).length
            ? payload.data.study_format
            : state.studyFormat,
        isOpenAiLoading: false,
      };
    case INCORPORATE_SUGGESTIONS_THROUGH_OPEN_AI_ERROR:
      return {
        ...state,
        aIResponseErrors: {
          ...state.aIResponseErrors,
          openAiSuggestionIncorporationError: payload.error,
        },
        isOpenAiLoading: false,
        ...payload,
      };
    case SET_PICO_GUID:
      return {
        ...state,
        PICOGuid: payload,
      };
    case SET_SUGGESTIONS_WITH_INDEX:
      const studyFormats = [...state.studyFormats];
      studyFormats[payload.index] = payload.data;
      return {
        ...state,
        studyFormats,
      };
    case HANDLE_ORDERDETAIL_MODAL:
      return {
        ...state,
        isNotificationModalOpen: payload,
      };

    case GET_ORDER_NOTIFICATION_DETAILS_START:
      return {
        ...state,
        orderNotificationDetailsLoading: true,
        orderNotificationDetailsError: undefined,
      };

    case GET_ORDER_NOTIFICATION_DETAILS_COMPLETE:
      return {
        ...state,
        orderNotificationDetails: payload.data,
        orderNotificationDetailsLoading: false,
      };

    case GET_ORDER_NOTIFICATION_DETAILS_ERROR:
      return {
        ...state,
        orderNotificationDetailsError: payload.error,
        orderNotificationDetailsLoading: false,
      };
    case RESET_ORDER_NOTIFICATION_DETAILS:
      return {
        ...state,
        orderNotificationDetails: undefined,
        orderNotificationDetailsError: undefined,
        orderNotificationDetailsLoading: false,
      };
    case HANDLE_CANCEL_ORDER_MODAL:
      return {
        ...state,
        orderGuidToCancel: payload,
      };

    case SET_CURRENT_ORDER_STATUS:
      return {
        ...state,
        currentOrderStatus: payload,
      };

    case SET_MOTIVATION:
      return {
        ...state,
        currentOrderMotivation: payload,
      };
    case UPDATE_PICO_SUGGESTIONS:
      return {
        ...state,
        studyFormats: payload,
      };
    case RESET_STUDY_FORMATS:
      return {
        ...state,
        studyFormats: [],
        studyFormat: {},
        aIResponseErrors: undefined,
        phenotypeResponse: undefined,
        rootPhenotypeResponse: undefined,
        PICOGuid: undefined,
        singleOrderData: {},
        isgettingOrderDetails: false,
        isOpenAiLoading: false,
        syncedPhenotype: undefined,
        orderTaskStatusError: undefined,
        orderPrognostogramError: undefined,
        isPhenotypeSyncing: false,
        phenotypeValidationsErrors: undefined,
        isPhenotypeLoading: false,
        phenotypeValidationsErrors: undefined,
        datasetSelectors: [],
        isDataSetsLoading: false,
      };
    case CLEAR_STUDY_FORMATS:
      return {
        ...state,
        studyFormats: [],
        aIResponseErrors: undefined,
        phenotypeResponse: undefined,
        rootPhenotypeResponse: undefined,
        openAiPhenotypeError: undefined,
      };
    case UPDATE_STUDY_DETAIL_DATA:
      return {
        ...state,
        studyFormats: payload,
      };
    case SET_PHENOTYPE_DATA:
      return {
        ...state,
        phenotypeResponse: {
          ...state.phenotypeResponse,
          phenotypes: payload?.phenotypeData,
          modified_params: Array.isArray(state.phenotypeResponse?.modified_params)
            ? payload?.phenotype
              ? [...state.phenotypeResponse.modified_params, payload.phenotype]
              : [...state.phenotypeResponse.modified_params]
            : payload?.phenotype
            ? [payload.phenotype]
            : state.phenotypeResponse?.modified_params || [],
        },
      };
    case SYNC_MODIFIED_PHENOTYPE_DATA_START:
      return {
        ...state,
        aIResponseErrors: {
          ...state.aIResponseErrors,
          syncedPhenotypeError: undefined,
        },
        phenotypeValidationsErrors: undefined,
        isPhenotypeSyncing: true,
      };
    case SYNC_MODIFIED_PHENOTYPE_DATA_COMPLETE:
      return {
        ...state,
        isPhenotypeSyncing: false,
        syncedPhenotype: payload?.data,
      };
    case SYNC_MODIFIED_PHENOTYPE_DATA_ERROR:
      return {
        ...state,
        syncedPhenotype: undefined,
        isPhenotypeSyncing: false,
        aIResponseErrors: {
          ...state.aIResponseErrors,
          syncedPhenotypeError: payload.error,
        },
        phenotypeValidationsErrors: payload?.errorObj,
      };
    case GET_STUDY_DETAILS_COMPLETE:
      return {
        ...state,
        studyDetails: payload,
      };
    case GET_STUDY_DETAILS_ERROR:
      return {
        ...state,
        studyDetails: {},
        aIResponseErrors: {
          ...state.aIResponseErrors,
          studyDetailsError: payload,
        },
      };
    case actions.HANDLE_PHENOTYPE_VALIDATION_ERROR:
      return {
        ...state,
        phenotypeValidationsErrors: {
          ...state.phenotypeValidationsErrors,
          ...payload,
        },
        aIResponseErrors: {
          ...state.aIResponseErrors,
          syncedPhenotypeError: undefined,
        },
      };
    case actions.CLEAR_PHENOTYPE_VALIDATION_ERROR:
      return {
        ...state,
        phenotypeValidationsErrors: undefined,
        aIResponseErrors: undefined,
      };
    case actions.SET_PHENOTYPE_ROOT_STATE:
      return {
        ...state,
        rootPhenotypeResponse: payload,
      };
    case actions.POST_DATASET_SELECTION_START:
      return {
        ...state,
        datasetSelectors: [],
        aIResponseErrors: {
          ...state.aIResponseErrors,
          datasetSelectorError: undefined,
        },
        isDataSetsLoading: true,
      };
    case actions.POST_DATASET_SELECTION_SUCCESS:
      return {
        ...state,
        datasetSelectors: payload,
        isDataSetsLoading: false,
      };
    case actions.POST_DATASET_SELECTION_FAILURE:
      return {
        ...state,
        datasetSelectors: [],
        isDataSetsLoading: false,
        aIResponseErrors: {
          ...state.aIResponseErrors,
          datasetSelectorError: payload?.error,
        },
      };
    default:
      return state;
  }
};

export default reducer;
