import axios from 'axios';

export const getSearchTerm = async ({ token, params }) => {
  return axios({
    method: 'post',
    url: `/prognostograms/orders/search/ambiguous/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: params,
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const searchAiOrders = async ({ token, params }) => {
  return axios({
    method: 'post',
    url: '/prognostograms/orders/search/ai/',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: params,
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const initiatePubmedSearch = async ({ token, params }) => {
  return axios({
    method: 'post',
    url: '/prognostograms/orders/search/pubmed-search/initiate/',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },

    data: params,
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const fetchPubmedSearchTaskStatus = async ({ token, params }) => {
  return axios({
    method: 'get',
    url: `/prognostograms/orders/search/pubmed-search/${params.job_id}/progress/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },

    data: params,
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};
