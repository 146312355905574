import React from 'react';
import { ImInfo } from 'react-icons/im';
import { Frame } from 'components/Frame';
import { labels } from 'constants/labels';
import CustomTooltip from 'components/OverlayTooltip/CustomTooltip';
import DataSourceTable from './DataSourceTable';
import { useSelector } from 'react-redux';
import { Button } from 'components/button';
import { ReactComponent as CheckGray } from 'assets/images/CheckGray.svg';
import { ReactComponent as Check } from 'assets/images/Check.svg';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';
import Message from 'components/Message';

function DataSourceSelection() {
  const { datasetSelectors, isDataSetsLoading } = useSelector(
    ({ orderDetails }) => orderDetails,
  );
  const { ordersPageData, dispatchOrderPageAction } = useOrdersPagedata();
  const {
    chatRWD: { selectedDataSourceOption, hasDataSetConfirmed },
  } = ordersPageData;

  function handleSubmitDataSet() {
    dispatchOrderPageAction({
      type: 'order/updateChatRWDField',
      payload: {
        field: 'hasDataSetConfirmed',
        value: true,
      },
    });
  }

  if (isDataSetsLoading) return null;

  if (datasetSelectors && datasetSelectors.length > 0) {
    return (
      <>
        <Frame>
          <Frame.Aside></Frame.Aside>
          <Frame.Body className="overflow-auto">
            <div className="w-100 d-flex flex-column gap-15">
              <div>
                <div className="mb-2 txt size-16 lh-28 text-slate-gray w-700">
                  {labels.chooseDataSource}
                </div>
                <div className="d-flex align-items-center gap-5">
                  <div className="txt size-16 lh-28 text-slate-gray">
                    {labels.rankedDataSources}{' '}
                  </div>{' '}
                  <CustomTooltip
                    className="mt-1"
                    position="bottom"
                    content={
                      <ImInfo
                        className="txt text-slate-gray iconposition-to-text"
                        size={15}
                      />
                    }
                    info="We ranked the data sources based on the phenotypes in your study design"
                  />
                </div>
              </div>
              <DataSourceTable />
            </div>
          </Frame.Body>
        </Frame>
        <Frame.Footer>
          <div className="d-flex flex-wrap flex-justify-end gap-10 d-md-flex">
            {!hasDataSetConfirmed ? (
              <Button
                outlined
                label={labels.confirmDataSet}
                icon={<CheckGray />}
                onClick={handleSubmitDataSet}
                disabled={!selectedDataSourceOption || !selectedDataSourceOption.length}
              />
            ) : (
              <Button
                label={labels.submitMotivation}
                icon={<Check />}
                cursor="not-allowed"
              />
            )}
          </div>
        </Frame.Footer>
      </>
    );
  }

  return (
    <div className="mt-2">
      <Message text="No Datset found" />
    </div>
  );
}

export default DataSourceSelection;
