import React, { useEffect, useRef } from 'react';
import { truncateText } from 'utils/strings';
import atroposLogo from '../../assets/images/atropos_fav_logo.png';
import styled from 'styled-components';
import { OverlayPanel } from 'primereact/overlaypanel';
import TruncatedText from 'components/truncatedText/TruncatedText';
import { labels } from 'constants/labels';
import { Avatar } from 'components/avatar';
import { AvatarGroup } from 'primereact/avatargroup';
import { useToken } from 'hooks/useToken';
import { useDispatch, useSelector } from 'react-redux';
import { getPrognostogramPdf } from 'redux/modules/orderDetails/actions';
import AuthLoader from 'components/AuthLoader';
import systemImage from 'assets/images/system.png';
import SourceInfo from './SourceInfo';
import { useMixpanel } from 'react-mixpanel-browser';

const StyledDiv = styled.div`
  background: var(--bg-white);
  padding: 20px;
  border: 1px solid var(--border-neutral-200);
  border-radius: var(--border-radius-8);
  display: flex;
  flex-direction: column;
  transition: background-color 0.3s ease, transform 0.3s ease;
  gap: 10px;
  min-width: 20%;
  &:hover {
    background-color: #e2e8e8;
    border: 1px solid #dcdcdc;
  }
  box-sizing: border-box;
  flex-wrap: wrap;
  flex: 1 0 100%;
  max-width: 100%;
  position: relative;
  cursor: pointer;

  @media (min-width: 768px) {
    flex: 1 0 calc(50% - 10px);
    max-width: ${(props) => props.width || 'calc(50% - 10px)'};
  }

  @media (min-width: 1024px) {
    flex: 1 0 calc(33.33% - 10px);
  }

  @media (min-width: 1200px) {
    flex: 1 0 calc(25% - 10px);
    max-width: ${(props) => props.width || 'calc(25% - 10px)'};
  }
`;

const StyledDot = styled.div`
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background: #b6c5c5;
`;

const StyledLoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .authloader {
    background-color: transparent !important;
  }
`;

function Source({
  source,
  onClick,
  activeIndex,
  index,
  showInfoOverlay = true,
  ...props
}) {
  const op = useRef(null);
  const token = useToken();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const hoverTimeout = useRef(null);
  const hoverOutTimeout = useRef(null);

  const { isPdfLoading, getPdfFileError } = useSelector(
    ({ orderDetails }) => orderDetails,
  );

  const title = truncateText(source?.title, 43) || truncateText(source?.study_title, 43);

  useEffect(() => {
    if (getPdfFileError) {
      op?.current?.hide();
    }
  }, [getPdfFileError]);

  const handleMouseEnter = (event) => {
    if (op.current) {
      op.current.toggle(event);

      if (hoverTimeout?.current) {
        clearTimeout(hoverTimeout.current);
      }

      hoverTimeout.current = setTimeout(() => {
        handleEventTracking({ open: true });
      }, 1500);
    }
  };

  const handleMouseLeave = (event) => {
    if (op.current) {
      op.current.hide();

      if (hoverOutTimeout?.current) {
        clearTimeout(hoverOutTimeout.current);
      }

      hoverOutTimeout.current = setTimeout(() => {
        handleEventTracking({ open: false });
      }, 1500);
    }
  };

  const handleClick = () => {
    if (source.source === labels.Publications) {
      if (source?.study_url)
        window.open(source?.study_url, '_blank', 'noopener,noreferrer');
    } else {
      if (source?.name !== labels?.otherSources) {
        dispatch(
          getPrognostogramPdf({
            token,
            orderGuid: source?.document_uri,
            id_type: 'case_uuid',
          }),
        );
      }
    }

    const { source_url, source: sourcename } = source;
    if (sourcename) {
      const event = `${sourcename} source clicked event`;
      mixpanel.track(event, {
        source: sourcename,
        source_url,
      });
    }

    if (op.current) {
      op.current.hide();
    }

    onClick(source?.document_uri);
  };

  function handleEventTracking({ open }) {
    const { source_url, source: sourcename } = source;
    const eventName = open
      ? 'source overlay opened event'
      : 'source overlay closed event';

    mixpanel.track(eventName, {
      source: sourcename,
      source_url,
    });
  }

  return (
    <>
      <StyledDiv
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        width={props.cardwidth}
      >
        <div className="d-flex align-items-center gap-10 w-100">
          <div className="txt size-14 lh-19 text-black-900 w-600">{source?.source}</div>
        </div>
        {props.isModal ? (
          <div className="txt lh-19 size-14 text-black-900">
            {truncateText(source?.title, 100) || truncateText(source?.study_title, 100)}
          </div>
        ) : (
          <div className="txt lh-19 size-14 text-black-900 break-word">{title}</div>
        )}

        {props.isModal && (
          <div className="txt lh-19 size-14 text-black-800">
            {truncateText(source?.content, 300) ||
              truncateText(source?.study_summary, 300)}
          </div>
        )}

        <div className="d-flex align-items-center gap-10 w-100 mt-auto">
          {source?.avatars?.length > 0 ? (
            <AvatarGroup className="ah-avatar-group">
              {source?.avatars?.map((avatar, index) => (
                <React.Fragment key={`${avatar}-${index}`}>
                  {avatar == 'atropos' ? (
                    <Avatar image={atroposLogo} shape="circle" />
                  ) : (
                    <Avatar
                      style={{
                        backgroundColor: '#f2f5f6',
                      }}
                      image={systemImage}
                      shape="circle"
                    />
                  )}
                </React.Fragment>
              ))}
            </AvatarGroup>
          ) : (
            <Avatar
              className="row-flex flex-shrink-0"
              image={source?.source === labels.Publications ? systemImage : atroposLogo}
              shape="circle"
            />
          )}

          {source?.name !== labels?.otherSources && (
            <>
              <TruncatedText
                text={source?.source_url}
                width={props.truncatedtextwidth || '55'}
                className="txt lh-19 size-14 text-black-900"
              />

              {source?.citation !== undefined && source?.citation !== null && (
                <>
                  <StyledDot></StyledDot>
                  <div className="txt lh-19 size-14 text-black-900 ">
                    {source?.citation}
                  </div>
                </>
              )}
            </>
          )}

          {isPdfLoading &&
            activeIndex &&
            activeIndex === source?.document_uri &&
            showInfoOverlay && (
              <StyledLoaderContainer>
                <AuthLoader fullScreen={false} />
              </StyledLoaderContainer>
            )}
        </div>

        {showInfoOverlay && (
          <OverlayPanel
            data-ignore-outside-click
            onClick={(e) => e.stopPropagation()}
            hide
            ref={op}
            style={{
              backgroundColor: 'var(--bg-white)',
              border: '1px solid var(--border-neutral-200)',
              borderRadius: '12px',
              padding: '16px',
              maxWidth: '400px',
              maxHeight: '350px',
              overflow: 'auto',
            }}
          >
            <SourceInfo source={source} />
          </OverlayPanel>
        )}
      </StyledDiv>
    </>
  );
}

export default Source;
