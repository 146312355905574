import * as searchOrderTypes from './types';

export const GET_SEARCH_SUGGESTION = 'searchOrder/getSearchSuggestion';
export const GET_SEARCH_SUGGESTION_START = 'searchOrder/getSearchSuggestionStart';
export const GET_SEARCH_SUGGESTION_COMPLETE = 'searchOrder/getSearchSuggestionCompleted';
export const GET_SEARCH_SUGGESTION_ERROR = 'searchOrder/getSearchSuggestionError';
export const CLEAR_SEARCH_TERM_DATA = 'orderDetails/clearSearchTermData';

export const getSearchSuggestion = (payload = {}) => ({
  type: GET_SEARCH_SUGGESTION,
  payload,
});

export const clearSearchTermData = (payload = {}) => ({
  type: CLEAR_SEARCH_TERM_DATA,
  payload,
});

export const setIsAISearchMode = (payload) => ({
  type: searchOrderTypes.SET_IS_AI_SEARCH_MODE,
  payload,
});

export const aiSearch = (payload) => ({
  type: searchOrderTypes.AI_SEARCH,
  payload,
});

export const setAISearchLoading = (payload) => ({
  type: searchOrderTypes.SET_AI_SEARCH_LOADING,
  payload,
});

export const stopBackgroundSync = (payload) => ({
  type: searchOrderTypes.STOP_BACKGROUND_SYNC,
  payload,
});

export const fetchPubMedRequest = (payload) => ({
  type: searchOrderTypes.FETCH_PUBMED_REQUEST,
  payload,
});

export const fetchPubMedSearchTaskStatus = (payload) => ({
  type: searchOrderTypes.PUBMED_SEARCH_TASK_STATUS,
  payload,
});
