import React from 'react';
import { useSelector } from 'react-redux';
import { SummaryLoaderLayout } from './AIOrderLoaderLayout';
import { labels } from 'constants/labels';
import styled from 'styled-components';
import Error from 'components/Error';
import SummaryHeader from './SummaryHeader';
import SummaryWithCitations from './SummaryWithCitations';
import Message from '../../components/Message';

const StyledDiv = styled.div`
  max-height: 50rem;
  overflow: auto;
`;

const data = [labels.theme, labels.summary, labels.score, labels.reasoning];

function PublicationSummary() {
  const { aiSearchState } = useSelector(({ searchOrder }) => searchOrder);
  const { PubMedSearchLoading, pubmedSearchError, synthesisThemes } = aiSearchState;

  if (PubMedSearchLoading)
    return (
      <SummaryLoaderLayout
        showsidediv={false}
        title={<SummaryHeader header={labels.publicationSummary} />}
      />
    );

  return (
    <StyledDiv className="d-flex flex-column gap-15">
      <SummaryHeader header={labels.publicationSummary} />
      {pubmedSearchError && <Error error={pubmedSearchError} />}

      {!pubmedSearchError && synthesisThemes && synthesisThemes?.length > 0
        ? synthesisThemes?.map((synthesisTheme, index) => (
            <div
              className="d-flex flex-column gap-7"
              key={`${synthesisTheme?.query}-${index}`}
            >
              {data?.map((label) => (
                <div key={label}>
                  <strong className="txt w-600 lh-21 size-16 text-capitalize">
                    {label}:{' '}
                  </strong>{' '}
                  <span className="txt w-400 text-slate-gray lh-21 size-16">
                    {label !== labels.summary ? (
                      label !== labels.score ? (
                        synthesisTheme[label]
                      ) : (
                        synthesisTheme.score.toFixed(1)
                      )
                    ) : (
                      <SummaryWithCitations synthesisTheme={synthesisTheme} />
                    )}
                  </span>
                </div>
              ))}
              {synthesisThemes?.length - 1 !== index && <hr />}
            </div>
          ))
        : !pubmedSearchError && (
            <Message severity="info" text={labels.noSummaryAvailable} />
          )}
    </StyledDiv>
  );
}

export default PublicationSummary;
