export const AI_SEARCH = 'orderDetails/aisearch';
export const AI_SEARCH_LOADING = 'orderDetails/aisearchLoading';
export const AI_SEARCH_COMPLETE = 'orderDetails/aisearchComplete';
export const AI_SEARCH_ERROR = 'orderDetails/aisearchError';
export const SET_AI_SEARCH_LOADING = 'orderDetails/setAisearchLoading';
export const STOP_BACKGROUND_SYNC = 'orderDetailsg/stopBackgroundSync';
export const STOP_BACKGROUND_SYNC_COMPLETE = 'orderDetailsg/stopBackgroundSyncComplete';
export const SET_IS_AI_SEARCH_MODE = 'orderDetails/setIsAISearchMode';

export const FETCH_PUBMED_REQUEST = 'FETCH_PUBMED_REQUEST';
export const FETCH_PUBMED_LOADING = 'FETCH_PUBMED_LOADING';
export const FETCH_PUBMED_SUCCESS = 'FETCH_PUBMED_SUCCESS';
export const FETCH_PUBMED_FAILURE = 'FETCH_PUBMED_FAILURE';

export const PUBMED_SEARCH_TASK_STATUS = 'orderDetails/pubmedSearchTaskStatus';
export const PUBMED_SEARCH_TASK_STATUS_SUCCESS =
  'orderDetails/pubmedSearchTaskStatusSuccess';
export const PUBMED_SEARCH_TASK_STATUS_FAILURE =
  'orderDetails/pubmedSearchTaskStatusFailure';
