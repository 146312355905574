import React from 'react';
import { StatusButton } from 'components/statusbutton';

function AnswerStatusIndicator({ button }) {
  if (button && button?.label)
    return (
      <StatusButton
        label={button.label}
        tooltipcontent={button?.tooltip_content}
        severity={button?.severity}
      />
    );

  return null;
}

export default AnswerStatusIndicator;
