import orderFormErrorStr from 'constants/errorStrings';
import { labels } from 'constants/labels';
import React from 'react';

function Error({ error }) {
  return (
    <div className="ah-alert ah-alert-error list-bullet order-page-error-container mt-2">
      <div className="color-error txt size-13 list-spacing">
        {error === orderFormErrorStr.commonError ? (
          <span>
            Something went wrong. Please{' '}
            <a href={`mailto:${labels.supportEmail}`} className="color-error">
              contact support
            </a>{' '}
            for help!
          </span>
        ) : (
          error
        )}
      </div>
    </div>
  );
}

export default Error;
