import React from 'react';
import { ImInfo } from 'react-icons/im';
import styles from './dataSourceTable.module.scss';
import { Checkbox } from 'components/form/checkbox';
import CustomTooltip from 'components/OverlayTooltip/CustomTooltip';
import { StatusButton } from 'components/statusbutton';
import { useSelector } from 'react-redux';
import { tableHeaders } from './data';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';

function DataSourceTable() {
  const { datasetSelectors } = useSelector(({ orderDetails }) => orderDetails);
  const { ordersPageData, dispatchOrderPageAction } = useOrdersPagedata();
  const {
    chatRWD: { selectedDataSourceOption, hasDataSetConfirmed },
  } = ordersPageData;

  function handleDataSetChange(e) {
    let _datasetOptions = [...selectedDataSourceOption];

    if (e.checked) _datasetOptions.push(e.value);
    else _datasetOptions.splice(_datasetOptions.indexOf(e.value), 1);

    dispatchOrderPageAction({
      type: 'order/updateChatRWDField',
      payload: {
        field: 'selectedDataSourceOption',
        value: _datasetOptions,
      },
    });

    if (!_datasetOptions || _datasetOptions.length === 0) {
      dispatchOrderPageAction({
        type: 'order/updateChatRWDField',
        payload: {
          field: 'hasDataSetConfirmed',
          value: false,
        },
      });
    }
  }

  function handleIsChecked(source) {
    return selectedDataSourceOption.some(
      (dataSource) =>
        dataSource?.dataset_name === source.dataset_name && dataSource.id === source.id,
    );
  }

  function handleKeysWithZeroVal(obj) {
    let zeroKeys = [];

    function findZeroValues(obj) {
      for (let key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          findZeroValues(obj[key]);
        } else if (obj[key] === 0) {
          zeroKeys.push(key);
        }
      }
    }

    findZeroValues(obj);
    return zeroKeys.length > 0 ? zeroKeys.join(', ') : 'none';
  }

  function renderTable(dataSet) {
    const { name, data_sources } = dataSet;
    return (
      <>
        <tr className={styles.leftspace}>
          <td className={styles.grouphead} colSpan="3">
            <div className="txt size-14 w-700 text-black-900 text-primary-1 py-1">
              {name}
            </div>
          </td>
        </tr>

        {data_sources?.map((source) => (
          <tr key={source.id}>
            <td>
              <div className="d-flex align-items-center gap-5">
                <div className="d-flex align-items-center">
                  <Checkbox
                    className="mt-1"
                    inputId={source?.dataset_name}
                    name={source?.dataset_name}
                    onChange={handleDataSetChange}
                    value={source}
                    checked={handleIsChecked(source)}
                    checkboxname={source?.dataset_name}
                    background="transparent"
                    disabled={hasDataSetConfirmed}
                  />
                  <label
                    className="txt size-14 text-black-900 w-500"
                    htmlFor={source?.dataset_name}
                  >
                    {source?.dataset_name}
                  </label>
                </div>

                <CustomTooltip
                  position="bottom"
                  content={
                    <ImInfo
                      className="txt text-slate-gray iconposition-to-text"
                      size={15}
                    />
                  }
                  info={source?.description || source?.dataset_name}
                />
              </div>
            </td>
            <td className="pl-2">
              <StatusButton
                severity={source?.color}
                label={
                  source?.color === 'green'
                    ? 'HIGH'
                    : source?.color === 'yellow'
                    ? 'MEDIUM'
                    : 'LOW'
                }
                size="small"
                className="w-100"
              />
            </td>
            <td className="txt size-14 text-slate-gray text-capitalize">
              {handleKeysWithZeroVal(source?.counts)}
            </td>
          </tr>
        ))}
      </>
    );
  }

  return (
    <div className={styles.dataSourceTableContainer}>
      <table className={styles.dataSourceTable}>
        <thead>
          <tr>
            {tableHeaders?.map((header) => (
              <th key={header} className="txt size-12 lh-18 text-black-900">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {datasetSelectors?.map((dataSet) => (
            <React.Fragment key={dataSet.name}>{renderTable(dataSet)}</React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default DataSourceTable;
