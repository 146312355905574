import React from 'react';
import InfoPanel from './InfoPanel';
import styled from 'styled-components';

const StyledBadge = styled.span`
  font-size: 15px;
  min-width: 25px;
  height: 25px;
  color: var(--color-slate-gray);
  background-color: var(--bg-neutral-200);
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: var(--bg-primary);
    color: var(--color-white);
  }
`;

function SummaryWithCitations({ synthesisTheme }) {
  const { summary, citation_nums, statement_findings } = synthesisTheme || {};
  function highlightCitations(text, citations) {
    const citationPattern = /\[(\d+)\]/g;
    let count = 1;

    return text.split(citationPattern).map((part, index) => {
      if (index % 2 !== 0) {
        const source = statement_findings?.find(
          (statementFinding) => statementFinding?.citation == part,
        );

        return citations.includes(part) ? (
          <React.Fragment key={source.finding_id}>
            <InfoPanel
              closeonClick="true"
              source={source}
              el={<StyledBadge>[{count++}]</StyledBadge>}
              isshowbutton={true}
            />
          </React.Fragment>
        ) : (
          <React.Fragment key={source.finding_id}>`[${part}]`</React.Fragment>
        );
      }
      return part;
    });
  }

  return highlightCitations(summary, citation_nums);
}

export default SummaryWithCitations;
