import { searchOrder } from 'api/';
import { call, takeLatest, put, fork, cancel } from 'redux-saga/effects';
import {
  GET_SEARCH_SUGGESTION,
  GET_SEARCH_SUGGESTION_START,
  GET_SEARCH_SUGGESTION_COMPLETE,
  GET_SEARCH_SUGGESTION_ERROR,
} from './actions';
import orderFormErrorStr from '../../../constants/errorStrings';
import * as searchOrderTypes from './types';
import * as orderDetailsActions from '../orderDetails/actions';

let taskArray = [];

export function* getSearchTermSuggestion({ payload: { token, params } }) {
  try {
    yield put({
      type: GET_SEARCH_SUGGESTION_START,
    });
    const { response, error } = yield call(searchOrder.getSearchTerm, {
      token,
      params,
    });
    if (!!response) {
      yield put({
        type: GET_SEARCH_SUGGESTION_COMPLETE,
        payload: {
          searchTerms: response.data.preferredTerms,
        },
      });
    } else {
      //Show error boundary if error is 500 or other
      yield put(
        onGetSearchTermSuggestionError({
          searchTerms: undefined,
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(
      onGetSearchTermSuggestionError({
        searchTerms: undefined,
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* fetchAiSearchResults({
  payload: { token, params, orderIndexData, fetchSearchQueryList },
}) {
  try {
    yield put({
      type: searchOrderTypes.AI_SEARCH_LOADING,
    });
    yield put(orderDetailsActions.resetPrognostogramPdf());

    const { response, error } = yield call(searchOrder.searchAiOrders, {
      token,
      params,
      orderIndexData,
    });

    if (!!response) {
      yield put({
        type: searchOrderTypes.AI_SEARCH_COMPLETE,
        payload: response.data,
      });

      fetchSearchQueryList();
    } else {
      yield put({
        type: searchOrderTypes.AI_SEARCH_ERROR,
        payload: {
          error: error?.data?.message
            ? error?.data?.message
            : orderFormErrorStr.commonError,
        },
      });
    }
  } catch (error) {
    yield put({
      type: searchOrderTypes.AI_SEARCH_ERROR,
      payload: {
        error: error?.data?.message ? error?.data.message : orderFormErrorStr.commonError,
      },
    });
  }
}

function* initiatePubmedSearch({
  payload: { params, token, onSuccessPubmedSearchInitiation },
}) {
  try {
    yield put({
      type: searchOrderTypes.FETCH_PUBMED_LOADING,
    });

    const { response, error } = yield call(searchOrder.initiatePubmedSearch, {
      params,
      token,
    });

    if (!!response) {
      // yield put({
      //   type: searchOrderTypes.FETCH_PUBMED_SUCCESS,
      //   payload: response.data,
      // });

      onSuccessPubmedSearchInitiation(response?.data?.job_id);
    } else {
      yield put({
        type: searchOrderTypes.FETCH_PUBMED_FAILURE,
        payload: {
          error: error?.data?.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        },
      });
    }
  } catch (error) {
    yield put({
      type: searchOrderTypes.FETCH_PUBMED_FAILURE,
      payload: {
        error: error?.data?.message ? error?.data.message : orderFormErrorStr.commonError,
      },
    });
  }
}

function* initiatePubmedSearchTaskStatus({
  payload: { params, token, onGetTaksStatus },
}) {
  try {
    // yield put({
    //   type: searchOrderTypes.FETCH_PUBMED_LOADING,
    // });

    const { response, error } = yield call(searchOrder.fetchPubmedSearchTaskStatus, {
      params,
      token,
    });

    if (!!response) {
      if (response?.data?.status === 'success' || response?.data?.status === 'failed') {
        yield put({
          type: searchOrderTypes.FETCH_PUBMED_SUCCESS,
          payload: response?.data?.result,
        });
      }

      onGetTaksStatus(response?.data);
    } else {
      yield put({
        type: searchOrderTypes.FETCH_PUBMED_FAILURE,
        payload: {
          error: error?.data?.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        },
      });
    }
  } catch (error) {
    yield put({
      type: searchOrderTypes.FETCH_PUBMED_FAILURE,
      payload: {
        error: error?.data?.message ? error?.data.message : orderFormErrorStr.commonError,
      },
    });
  }
}

const onGetSearchTermSuggestionError = (payload = {}) => ({
  type: GET_SEARCH_SUGGESTION_ERROR,
  payload,
});

function* startAiSearch(payload) {
  const newTask = yield fork(fetchAiSearchResults, payload);
  taskArray.push(newTask);
}

function* startPubmedSearch(payload) {
  const newTask = yield fork(initiatePubmedSearch, payload);
  taskArray.push(newTask);
}

export function* stopBackgroundTasks() {
  if (taskArray && taskArray.length) {
    for (let i = 0; i < taskArray.length; i++) {
      yield cancel(taskArray[i]);
    }
    taskArray = [];
    yield put({
      type: searchOrderTypes.STOP_BACKGROUND_SYNC_COMPLETE,
    });
  }
}

function* watchSearchTermSuggestion() {
  yield takeLatest(GET_SEARCH_SUGGESTION, getSearchTermSuggestion);
}

function* watchAiSearch() {
  yield takeLatest(searchOrderTypes.AI_SEARCH, startAiSearch);
}

function* watchPubmedSearch() {
  yield takeLatest(searchOrderTypes.FETCH_PUBMED_REQUEST, startPubmedSearch);
}

function* watchPubmedSearchTaskStatus() {
  yield takeLatest(
    searchOrderTypes.PUBMED_SEARCH_TASK_STATUS,
    initiatePubmedSearchTaskStatus,
  );
}

function* watchStopBackgroundTasks() {
  yield takeLatest(searchOrderTypes.STOP_BACKGROUND_SYNC, stopBackgroundTasks);
}

export const sagas = [
  watchSearchTermSuggestion,
  watchAiSearch,
  watchPubmedSearch,
  watchPubmedSearchTaskStatus,
  watchStopBackgroundTasks,
];
