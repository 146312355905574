import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import styled from 'styled-components';

const severityStyles = {
  info: {
    background: 'var(--bg-info)',
    border: 'var(--border-info)',
    color: 'var(--color-info)',
    icon: <AiOutlineInfoCircle />,
  },
};

const MessageContainer = styled.div`
  padding: 12px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 400;
  background: ${({ severity }) =>
    severityStyles[severity]?.background || 'var(--bg-info)'};
  border: ${({ severity }) => severityStyles[severity]?.border || 'var(--border-info)'};
  color: ${({ severity }) => severityStyles[severity]?.color || 'var(--color-info)'};
  border-style: solid;
  border-width: 0.5px;
`;

const StyledIcon = styled.span`
  position: relative;
  top: 3px;
`;

const Message = ({ severity = 'info', text }) => {
  const { icon } = severityStyles[severity] || severityStyles['info'];

  return (
    <MessageContainer severity={severity}>
      <StyledIcon>{icon}</StyledIcon> {text}
    </MessageContainer>
  );
};

export default Message;
